body {
    margin: 0;
    font-family: 'Barlow', sans-serif !important;
}

::selection {
    background-color: #FFCF01;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Barlow', sans-serif !important;
    color: #333333;
}

p {
    font-size: 1.125rem;
    color: #333333;
    font-family: 'Barlow', sans-serif !important;
}

.fw-900 {
    font-weight: 900 !important;
}

.fw-800 {
    font-weight: 800 !important;
}

.fw-400 {
    font-weight: 400 !important;
}

.fs-70 {
    font-size: 4.375rem;
}

.fs-60 {
    font-size: 3.75rem;
    line-height: 4rem;
}

.fs-48 {
    font-size: 3rem;
}

.fs-36 {
    font-size: 2.5rem;
}

.fs-24 {
    font-size: 1.5rem;
}

.fs-20 {
    font-size: 1.25rem;
}

.fs-18 {
    font-size: 1.125rem;
}

.fs-16 {
    font-size: 1rem;
}

@media screen and (max-width: 1200px) {

    .fs-70,
    .fs-60 {
        font-size: 3rem;
    }

    .fs-48 {
        font-size: 2.5rem;
    }
}

.vh-75 {
    min-height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vh-50 {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.color-sun {
    color: #FFCF01;
}

.color-light-forest {
    color: #009247;
}

.color-light-steel {
    color: #aaaaaa;
}

.bg-sun {
    background-color: #FFCF01;
}

.bg-steel {
    background-color: #333333;
}

.bg-silver {
    background-color: #F5F5F5;
}

.nav-item {
    color: #333333 !important;
    text-decoration: none !important;
    margin: .5rem 1.5rem 0rem 0rem !important;
    transition: .15s ease-in;
    border-bottom: 1px solid #fff;
}

.nav-item:hover,
.nav-item:active,
.navbar-nav .active {
    border-bottom: 3px solid #FFCF01;
    text-shadow: -.25px 0 #333333, 0 .25px #333333, .25px 0 #333333, 0 -.25px #333333;
    transition: .15s ease-out;
}